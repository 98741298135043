<style scoped>
.mobile-menu-active {
    display: block !important;
}

.notice-bar {
    text-align: center;
    color: white;
    background-color: red;
    padding: 5px;
}
</style>
<template>
    <div class="page">
        <div class="notice-bar" v-if="!buyAvaible">
            Spaceship purchasing is currently unavailable. Please check back soon!
        </div>
        <header class="header" style="height: 0px">
            <div class="header-main">
                <div class="container">
                    <div class="header-inner position-relative">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-6 col-sm-9 col-6">
                                <div class="d-flex align-items-center">
                                    <div class="logo" style="width: 200px">
                                        <RouterLink style="color: white; font-weight: 400; font-size: 18px" to="/"><img width="150" src="../static/images/NovaFrontier.png" class="main-logo" alt="" />
                                            <img width="150" src="../static/images/NovaFrontier.png" class="sticky-logo" alt="" />
                                        </RouterLink>
                                    </div>

                                    <!--
                                    <form class="search-form d-none d-sm-block ms-4" action="/">
                                        <div class="form-group">
                                            <button type="submit">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="11.997" height="12" viewBox="0 0 11.997 12" class="svg replaced-svg">
                                                    <path id="search" d="M18.918,17.912l-2.306-2.306a5.3,5.3,0,1,0-1.006,1.006l2.306,2.306a.711.711,0,0,0,1.006-1.006ZM9.68,15.14a3.862,3.862,0,1,1,2.731,1.131A3.866,3.866,0,0,1,9.68,15.14Z" transform="translate(-7.129 -7.125)" fill="#fff"></path>
                                                </svg>
                                            </button>
                                            <input type="text" class="form-control mb-0" placeholder="Search item…" />
                                        </div>
                                    </form>
                                    -->
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-6 col-sm-3 col-6 d-flex align-items-center justify-content-end position-static">
                                <div class="nav-wrapper d-flex align-items-center">
                                    <div id="menu-button" @click="mobileMenuActive = !mobileMenuActive"><span></span></div>



                                    <div v-if="version == 2" class="nav-wrap-inner" :class="{ 'mobile-menu-active': mobileMenuActive }">
                                        <ul class="nav" @click="mobileMenuActive = false">
                                            <li class="has-sub-item">
                                                <RouterLink to="/">Home</RouterLink>
                                            </li>
                                            <li class="has-sub-item">
                                                <RouterLink to="/top">Explore</RouterLink>
                                            </li>
                                            <li class="has-sub-item">
                                                <RouterLink to="/shop">Shop</RouterLink>
                                            </li>
                                            <li class="has-sub-item">
                                                <a href="https://novafrontier.io/support/" targe="_blank">Support</a>
                                            </li>
                                            <li class="has-sub-item">
                                                <RouterLink to="/top">Top</RouterLink>
                                            </li>
                                            <li class="has-sub-item">
                                                <RouterLink to="/market">Marketplace</RouterLink>
                                            </li>
                                            <li class="has-sub-item">
                                                <RouterLink to="/leaderboard">Leaderboard</RouterLink>
                                            </li>
                                            <li>
                                                <a href="#contact">Support</a>
                                            </li>
                                            <template v-if="!status.loggedIn">
                                                <li>
                                                    <router-link to="/login">Login</router-link>
                                                </li>
                                            </template>
                                        </ul>
                                    </div>

                                    <div v-if="version == 1" class="nav-wrap-inner" :class="{ 'mobile-menu-active': mobileMenuActive }">
                                        <ul class="nav" @click="mobileMenuActive = false">
                                            <li class="has-sub-item">
                                                <RouterLink to="/top">Top</RouterLink>
                                            </li>
                                            <li class="has-sub-item">
                                                <RouterLink to="/market">Marketplace</RouterLink>
                                            </li>
                                            <li class="has-sub-item">
                                                <RouterLink to="/shop">Shop</RouterLink>
                                            </li>

                                            <li class="has-sub-item">
                                                <a href="https://novafrontier.io/support/" target="_blank">Support</a>
                                            </li>
                                            <template v-if="!status.loggedIn">
                                                <li>
                                                    <router-link to="/login">Login</router-link>
                                                </li>
                                            </template>
                                        </ul>
                                    </div>





                                    <template v-if="status.loggedIn">
                                        <div class="d-md-flex align-items-center ms-4">
                                            <div class="dropdown ml-10">
                                                <a class="user-avatar text-white dropdown-toggle" data-bs-toggle="dropdown">
                                                    Profile
                                                    <img v-if="user.avatar" width="45px" height="45x" :src="user.avatar" style="width: 45px !important;" alt="" onerror="this.src='/noavatar.svg'" />
                                                    <img v-else width="45px" height="45x" src="/noavatar.svg" style="width: 45px !important;" alt="" />
                                                    <span class="user-status"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" class="svg replaced-svg">
                                                            <path id="check" d="M120.9,160.047l-4.161,3.961-.829.792-3.01-3.152,1.135-1.188,1.924,2.014.781-.742,3.083-2.933Z" transform="translate(-112.899 -158.8)" fill="#fff"></path>
                                                        </svg></span></a>
                                                <ul class="dropdown-menu">
                                                    <li>
                                                        <a v-if="!user.walletAddress" @click="connectMetamask" class="dropdown-item">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-wallet" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"></path>
                                                                <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"></path>
                                                            </svg>
                                                            Connect Wallet
                                                        </a>
                                                        <a v-else @click="connectMetamask" class="dropdown-item">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-wallet" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"></path>
                                                                <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"></path>
                                                            </svg>
                                                            {{ cutWalletAddress(user.walletAddress) }}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <RouterLink class="dropdown-item" to="/my-assets">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-vector-triangle" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M10 4m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
                                                                <path d="M3 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
                                                                <path d="M17 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
                                                                <path d="M6.5 17.1l5 -9.1" />
                                                                <path d="M17.5 17.1l-5 -9.1" />
                                                                <path d="M7 19l10 0" />
                                                            </svg>
                                                            My Assets
                                                        </RouterLink>
                                                    </li>
                                                    <li>
                                                        <RouterLink class="dropdown-item" to="/my/goaland">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-map-pin-share" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                                                <path d="M12.02 21.485a1.996 1.996 0 0 1 -1.433 -.585l-4.244 -4.243a8 8 0 1 1 13.403 -3.651" />
                                                                <path d="M16 22l5 -5" />
                                                                <path d="M21 21.5v-4.5h-4.5" />
                                                            </svg>
                                                            GOALAND Staking
                                                        </RouterLink>
                                                    </li>
                                                    <li>
                                                        <RouterLink class="dropdown-item" to="/my/blindbox">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-archive">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                                                                <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                                                                <path d="M10 12l4 0" />
                                                            </svg>
                                                            Blind Boxes
                                                        </RouterLink>
                                                    </li>
                                                    <li>
                                                        <RouterLink class="dropdown-item" to="/my/penguins">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-building-stadium" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M12 12m-8 0a8 2 0 1 0 16 0a8 2 0 1 0 -16 0" />
                                                                <path d="M4 12v7c0 .94 2.51 1.785 6 2v-3h4v3c3.435 -.225 6 -1.07 6 -2v-7" />
                                                                <path d="M15 6h4v-3h-4v7" />
                                                                <path d="M7 6h4v-3h-4v7" />
                                                            </svg>
                                                            Equip Penguins
                                                        </RouterLink>
                                                    </li>
                                                    <li>
                                                        <RouterLink class="dropdown-item" to="/my/gin-exchange">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-coinbase" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M12.95 22c-4.503 0 -8.445 -3.04 -9.61 -7.413c-1.165 -4.373 .737 -8.988 4.638 -11.25a9.906 9.906 0 0 1 12.008 1.598l-3.335 3.367a5.185 5.185 0 0 0 -7.354 .013a5.252 5.252 0 0 0 0 7.393a5.185 5.185 0 0 0 7.354 .013l3.349 3.367a9.887 9.887 0 0 1 -7.05 2.912z" />
                                                            </svg>
                                                            Gin Exchange
                                                        </RouterLink>
                                                    </li>
                                                    <li>
                                                        <RouterLink class="dropdown-item" to="/my/redeem">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-badges">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M17 17v-4l-5 3l-5 -3v4l5 3z" />
                                                                <path d="M17 8v-4l-5 3l-5 -3v4l5 3z" />
                                                            </svg>
                                                            Redeem Spaceship
                                                        </RouterLink>
                                                    </li>
                                                    <li>
                                                        <RouterLink class="dropdown-item" to="/change-profile">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-scan" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M10 9a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                                                <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />
                                                                <path d="M4 16v2a2 2 0 0 0 2 2h2" />
                                                                <path d="M16 4h2a2 2 0 0 1 2 2v2" />
                                                                <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
                                                                <path d="M8 16a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2" />
                                                            </svg>
                                                            Change Profile
                                                        </RouterLink>
                                                    </li>
                                                    <li>
                                                        <RouterLink class="dropdown-item" to="/change-password">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-key" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M16.555 3.843l3.602 3.602a2.877 2.877 0 0 1 0 4.069l-2.643 2.643a2.877 2.877 0 0 1 -4.069 0l-.301 -.301l-6.558 6.558a2 2 0 0 1 -1.239 .578l-.175 .008h-1.172a1 1 0 0 1 -.993 -.883l-.007 -.117v-1.172a2 2 0 0 1 .467 -1.284l.119 -.13l.414 -.414h2v-2h2v-2l2.144 -2.144l-.301 -.301a2.877 2.877 0 0 1 0 -4.069l2.643 -2.643a2.877 2.877 0 0 1 4.069 0z" />
                                                                <path d="M15 9h.01" />
                                                            </svg>
                                                            Change Password
                                                        </RouterLink>
                                                    </li>
                                                    <li>
                                                        <hr />
                                                        <a @click="doLogout" class="dropdown-item"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-logout" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                                                                <path d="M9 12h12l-3 -3" />
                                                                <path d="M18 15l3 -3" />
                                                            </svg> Logout</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- Navbar -->
        <router-view v-slot="{ Component }">
            <component :is="Component" />
        </router-view>
        <footer class="footer">
            <div class="footer-main" v-if="false">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6">
                            <div class="widget widget_nav_menu">
                                <h4 class="widget_title">My Account</h4>
                                <ul>
                                    <li>
                                        <a @click="connectMetamask">Connect Wallet</a>
                                    </li>
                                    <li>
                                        <RouterLink to="/my">My Assets</RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink to="/my/goaland">GOALAND Staking</RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink to="/my/blindbox">Blind Boxs</RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink to="/my/penguins">Penguin Pairing</RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink to="/my/gin-exchange">Gin Exchange</RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink to="/my/redeem">Redeem Spaceship</RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink to="/change-profile">Change Profile</RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink to="/change-password">Change Password</RouterLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6">

                        </div>
                        <div class="col-xl-3 col-sm-6">
                            <div class="widget widget_nav_menu">
                                <h4 class="widget_title">Marketplace</h4>
                                <div class="footer-list-wrap">
                                    <ul>
                                        <li>
                                            <RouterLink to="/top">Explore</RouterLink>
                                        </li>
                                        <li>
                                            <RouterLink to="/shop">Shop</RouterLink>
                                        </li>
                                        <li>
                                            <RouterLink to="/leaderboard">Leaderboard</RouterLink>
                                        </li>
                                        <li>
                                            <RouterLink to="/tos">Terms of Service</RouterLink>
                                        </li>
                                        <li>
                                            <RouterLink to="/privacy">Privacy Policy</RouterLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6">
                            <div class="widget widget_newsletter">
                                <h4 class="widget_title">Newsletter</h4>
                                <form action="/" class="newslatter-form">
                                    <div class="theme-input-group">
                                        <input class="form-control" type="email" placeholder="Enter Your Email" />
                                        <button type="submit" class="submit-btn btn">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" class="svg replaced-svg">
                                                <path id="mail" d="M11.524,19a6.834,6.834,0,0,1,6.667-7,6.392,6.392,0,0,1,2.857.68V6a1.96,1.96,0,0,0-1.9-2H3.9A1.96,1.96,0,0,0,2,6V18a1.96,1.96,0,0,0,1.9,2h7.7A6.959,6.959,0,0,1,11.524,19ZM3.9,6l7.619,5,7.619-5V8l-7.619,5L3.9,8Zm12.7,16-3.371-3.54,1.343-1.41L16.6,19.17l4.038-4.24L22,16.34Z" transform="translate(-2 -4)" fill="#fff"></path>
                                            </svg>
                                            Subscribe
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="copyright-content text-left text-white">
                                © 2024 -
                                <a class="text-white">
                                    Nova Frontier X</a> by The Tipsy Company. A registered entity under the Republic of the Marshall Islands (Reg. No. 114167).
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <span class="text-white" style="float: right;">
                                All prices are in USD | <RouterLink to="/tos" class="text-white">Terms & Conditions</RouterLink> | <RouterLink to="/privacy" class="text-white">Privacy Policy</RouterLink>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <PaymentDialog></PaymentDialog>

        <a href="#" class="back-to-top"><img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgYmFzZVByb2ZpbGU9InRpbnkiIGhlaWdodD0iMjRweCIgdmVyc2lvbj0iMS4yIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNHB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZyBpZD0iTGF5ZXJfMSI+PHBhdGggZD0iTTEzLDUuNTg2bC00LjcwNyw0LjcwN2MtMC4zOTEsMC4zOTEtMC4zOTEsMS4wMjMsMCwxLjQxNHMxLjAyMywwLjM5MSwxLjQxNCwwTDEyLDkuNDE0VjE3YzAsMC41NTIsMC40NDcsMSwxLDEgICBzMS0wLjQ0OCwxLTFWOS40MTRsMi4yOTMsMi4yOTNDMTYuNDg4LDExLjkwMiwxNi43NDQsMTIsMTcsMTJzMC41MTItMC4wOTgsMC43MDctMC4yOTNjMC4zOTEtMC4zOTEsMC4zOTEtMS4wMjMsMC0xLjQxNEwxMyw1LjU4NnogICAiLz48L2c+PC9zdmc+" /></a>
    </div>
</template>

<script>
//import { RouterLink } from "vue-router";
//import AccountModule from "./components/accountModule.vue";
import { mapState } from "vuex";
import PaymentDialog from "./modals/paymentDialog.vue";
//import LandCreditTopup from "./modals/landCreditTopup.vue";
//import LandPlotBuy from "./modals/landPlotBuy.vue";
//import axios from "axios";
//import $ from "jquery";
export default {
    data() {
        return {
            mobileMenuActive: false,
            API_URL: process.env.VUE_APP_BASE_URL,
            version: 1
        };
    },
    components: { PaymentDialog },
    computed: {
        ...mapState("account", ["status", "user", "creditAmount"]),
        ...mapState("map", ["openLoading"]),
        ...mapState("swal", ["popup", "hidePopup"]),
        ...mapState("web3_login", ["loginRequestStatus"]),
        ...mapState("website", ["buyAvaible"])
    },
    watch: {
        popup: function (payload) {
            //let { title, type, showConfirmButton, allowOutsideClick, msg } = payload;
            //this.$swal(msg, title, type);
            /*this.$swal.fire({
                title: title,
                icon: type,
                showDenyButton: false,
                showCancelButton: false,
                showConfirmButton: (showConfirmButton != undefined) ? showConfirmButton : true,
                allowOutsideClick: (allowOutsideClick != undefined) ? allowOutsideClick : true,
                text: msg ? msg : undefined
            });*/
            payload.icon = payload.type;
            payload.text = payload.msg;
            this.$swal.fire(payload).then(e => {
                console.log('t', e);
                if (payload.callback != null) {
                    payload.callback(e);
                }
            });
        },
        hidePopup: function () {
            this.$swal.close();
        },
        loginRequestStatus: function () {
            let e = this.loginRequestStatus;
            if (!e.error) {
                this.$store.dispatch("account/checkSession");
            } else {
                this.$swal(e.msg, "", e.error ? "error" : "success");
            }
        },
    },
    methods: {
        cutWalletAddress(str) {
            return str.substr(0, 5) + "..." + str.substr(str.length - 5);
        },
        connectMetamask() {
            if (!this.status.loggedIn) {
                this.$swal
                    .fire({
                        title: "Connect Wallet",
                        html: 'Please login to connect your wallet',
                        showDenyButton: false,
                        showCancelButton: false
                    })
                    .then(() => {
                    });
                return;
            }
            this.$swal
                .fire({
                    title: "Connect Wallet",
                    html: 'Connect your wallet to link your account to your wallet address. This enables you to perform blockchain transactions such as managing your NFTs and GIN rewards. <br>',
                    showDenyButton: true,
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButton: "connect-metamask",
                    confirmButtonText: '<a> Connect MetaMask</a>',
                    denyButtonText: `<a> Connect SafePal</a>`,
                    customClass: {
                        confirmButton: 'your-confirm-button-class',
                        cancelButton: 'your-cancel-button-class',
                        denyButton: 'your-deny-button-class',
                    },
                })
                .then((result) => {
                    if (result.isConfirmed || result.isDenied) {
                        this.$store.dispatch("web3_login/metamask");
                    }
                });
        },
        doLogout() {
            this.$swal
                .fire({
                    title: "Are you sure you want to logout?",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Logout",
                    denyButtonText: `Cancel`,
                })
                .then((result) => {
                    result.isConfirmed &&
                        this.$store.dispatch("account/logout") &&
                        this.$store.dispatch("cart/removeItemAll");
                });
        }
    },
    mounted() {
        //$.getScript(this.API_URL + "/static/main.js");
        this.$store.dispatch("account/checkSession");
        this.$store.dispatch("website/getSetting");
    }
};
</script>
